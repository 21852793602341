import GoogleMapReact from 'google-map-react';
import React, { useContext, useEffect, useState } from 'react';
// import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { AppointmentContext } from '../../../context/AppointmentBook/AppointmentBook';
import { appointmentData } from '../../../data/appointmentData/appointmentData';
import { timeListData } from '../../../data/doctorListData/doctorListData';
import { avlLoc } from '../../../pages/MapLocation/MapLocation';
import MyMarker from './MyMarker';

const points = [
  {
    id: 1234,
    title: 'Affordable Dentist Near Me - Crowley',
    subTitle: '',
    lat: 32.578079,
    lng: -97.358253,
    address: '200 E Main St Crowley, TX 76036',
    mob: '+682-708-6046',
    isSelected: false,
  },
  {
    id: 1235,
    title: 'Affordable Dentist Near Me - Grand Prairie',
    subTitle: '',
    lat: 32.6754,
    lng: -97.04165,
    mob: '+1-817-200-7086',
    address: '2501 I-20 Grand Prairie, TX 75052',
    isSelected: false,
  },
];

function distanceToMouse({ x, y }, { x: mouseX, y: mouseY }) {
  return Math.sqrt((x - mouseX) * (x - mouseX) + (y - mouseY) * (y - mouseY));
}

export default function MapRoute({ locationList }) {
  const {
    setBookedAddress,
    setSelectedCareer,
    selectedCareer,
    setAptTypeData,
    pointsData,
    setPointsData,
    selectedAdd,
    setSelectedAdd,
  } = useContext(AppointmentContext);

  const [activeLocation, setActiveLocation] = useState(points[0]);
  const [isEditable, setIsEditable] = useState(true);
  const [updateDom, setUpdateDom] = useState(false);

  const [config, setConfig] = useState({
    center: {
      lat: 32.6754,
      lng: -97.04165,
    },
    zoom: 8,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const locationId = query.get('lid');

  useEffect(() => {
    document.title = 'Dental - Dental Booking Online';
  }, []);

  useEffect(() => {
    if (locationList.length > 0) {
      if (location.pathname === '/') {
        setIsEditable(true);
        setPointsData(avlLoc(locationList));
      } else {
        const queryLocation = avlLoc(locationList).filter(
          (item) => item.id === Number(locationId)
        );

        if (queryLocation?.length > 0) {
          setIsEditable(false);
          setPointsData(queryLocation);
          setBookedAddress({ ...queryLocation[0], isSelected: false });
          setConfig({
            ...config,
            center: {
              lat: queryLocation[0].lat,
              lng: queryLocation[0].lng,
            },
            zoom: 15,
          });
        } else {
          navigate('/');
        }
      }
    }
  }, [locationId, locationList]);

  useEffect(() => {
    setTimeout(() => {
      setUpdateDom(true);
    }, 500);
  }, [updateDom]);

  const handleClick = (id) => {
    const selLoc = pointsData.find((item) => item.id === id);

    setActiveLocation({ ...selLoc, isSelected: true });
    const tempSelLoc = [...pointsData];

    const filLoc = tempSelLoc?.map((item, idx) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return { ...item, isSelected: false };
      }
    });
    setPointsData(filLoc);
  };

  const onLocationSelect = (id) => {
    const selAdd = locationList.find((item) => item.id === id);
    const { providerId, operatoryID } = selAdd;
    setSelectedAdd({ operatoryID, providerId });

    const selLoc = pointsData.find((item) => item.id === id);

    setBookedAddress({ ...selLoc, isSelected: false });

    navigate(`/book-appointment?lid=${id}`);

    const tempAptData = [...appointmentData];
    tempAptData?.map((item) => (item.isSelect = false));
    setAptTypeData(tempAptData);

    const tempTimeSlotData = [...timeListData];

    tempTimeSlotData?.map((timeItem, acIdx) => {
      timeItem.timeList?.map(
        (timeSlotItem, sbIdx) => (timeSlotItem.isSelect = false)
      );
    });

    setSelectedCareer({
      ...selectedCareer,
      firstStep: true,
      secondStep: false,
      thirdStep: false,
      fourthStep: false,
      finalStep: false,
      success: false,
    });
    setIsEditable(false);
  };

  return (
    // Important! Always set the container height explicitly
    <>
      {updateDom && (
        <div style={{ height: '99.9vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyBL2F5xZ4UkjJQwWqNyQrCIk_m2RfefIdQ',
            }}
            // defaultCenter={config.center}
            defaultZoom={config.zoom}
            distanceToMouse={distanceToMouse}
            center={config.center}
          >
            {pointsData?.map(({ lat, lng, id, title, isSelected }) => {
              return (
                <MyMarker
                  key={id}
                  lat={lat}
                  lng={lng}
                  title={title}
                  isSelected={isSelected}
                  activeLocation={activeLocation}
                  handleClick={() => handleClick(id)}
                  isEditable={isEditable}
                  onLocationSelect={() => onLocationSelect(id)}
                />
              );
            })}
            {/* <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" /> */}
          </GoogleMapReact>
        </div>
      )}
    </>
  );
}
