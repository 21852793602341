import { useEffect, useState } from 'react';
import './App.scss';
import 'antd/dist/antd.min.css';
import AppointmentContextProvider from './context/AppointmentBook/AppointmentBook';
import Navigation from './route/route';
import { defaultLogOut, getUser } from './services';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from 'react-bootstrap';
import ErrorFound from './pages/notfound/ErrorFound';

function App() {
  const handleError = (error, errorInfo) => {};
  const token = localStorage.getItem('_t');

  useEffect(() => {
    const checkExpTimeAndLogOut = () => {
      const expTime = localStorage.getItem('expTime');

      // If expTime is not null or undefined
      if (expTime) {
        // Convert expTime to a Date object
        const expTimeDate = new Date(expTime);

        // Get the current time as a Date object
        const currentTime = new Date();

        // Check if the current time is equal to or past the expiration time
        if (currentTime >= expTimeDate) {
          defaultLogOut();
        }
      }
    };

    checkExpTimeAndLogOut();

    const intervalId = setInterval(checkExpTimeAndLogOut, 1000); // Check every 1 second

    return () => clearInterval(intervalId);
  }, [token]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFound} onError={handleError}>
      <AppointmentContextProvider>
        {/* <AppointmentBook /> */}
        <Navigation />
      </AppointmentContextProvider>
      {/* <BuggyComponent /> */}
    </ErrorBoundary>
  );
}

export default App;
