import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  availabilityList: [],
  isLoading: false,
  isError: null,
  status: '',
  slots: [],
  slotsLoading: false,
  slotsError: false,
};

export const fetchAvailability = createAsyncThunk(
  'availability/availabilityList',
  async (inputFields, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Availability/get-availability?clinicid=${inputFields}`
      );
      // const newData = data?.map((o) => {
      //   return {
      //     ...o,
      //     inactive: o.active ? "active":"inactive",
      //     createdOnForUpdate:o.createdOn,
      //     createdOn: moment(new Date(o.createdOn)).format("MM/DD/YYYY"),
      //   };
      // });
      // const newData = _?.map(data, (o) => {
      //   return {
      //     ...o,
      //     createdOn: moment(new Date(o.createdOn)).format("MM/DD/YYYY hh:mm A"),
      //   };
      // });

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const availabilitySlice = createSlice({
  name: 'availabilityList',
  initialState,
  extraReducers: {
    [fetchAvailability.fulfilled]: (state, { payload }) => {
      state.availabilityList = payload;
      state.isLoading = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchAvailability.pending]: (state, { payload }) => {
      state.availabilityList = [];
      state.isLoading = true;
      state.status = '';
      state.isError = null;
    },
    [fetchAvailability.rejected]: (state, { payload }) => {
      state.availabilityList = [];
      state.isLoading = false;
      state.isError = payload;
      state.status = 'bad req';
    },
  },
});

export default availabilitySlice.reducer;
