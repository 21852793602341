import React, { useEffect } from 'react';
import { defaultLogOut } from '../../services';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { submitException } from '../../redux/exceptionFESlice/ErrorBoundarySlice';

const ErrorFound = ({ error }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const url = window.location.host;

    if (error && url !== 'localhost:3000') {
      console.log('1', 1);
      dispatch(submitException(`${error.message} \n ${error.stack}`));
    }
  }, [error]);

  const resetErrorBoundary = () => {
    if (error.message.includes('Loading CSS chunk 9686 failed')) {
      window.location.reload();
    } else {
      defaultLogOut();
      window.location.reload();
    }
  };
  return (
    <div
      className='404-container'
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '600px',
          position: 'relative',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '600px',
          margin: 'auto',
        }}
      >
        <div style={{ position: 'absolute', top: '8%', left: '27%' }}>
          <img src='../assets/img/oops.png' alt='OOPS' width='150px' />
        </div>
        <img src='../assets/img/404page.png' alt='404' width='100%' />
        <h2 style={{ fontSize: '27px', fontWeight: '600', color: '000000' }}>
          Something went wrong.
        </h2>
        <p style={{ fontSize: '18px', color: '00000e' }}>
          {/* Sorry , we can't find the page you are looking for. */}
        </p>
        {/* <button
          onClick={resetErrorBoundary}
          type='button'
          style={{
            // width: '150px',
            padding: '5px 18px',
            borderRadius: '8px',
            backgroundColor: '#007DFE',
            fontSize: '18px',
            fontWeight: 300,
            color: 'white',
            border: 'none',
          }}
        >
          Refresh
        </button> */}
        <Button
          type='submit'
          style={{ width: '300px' }}
          onClick={resetErrorBoundary}
          className={`ne_btn ne_btn_primary `}
        >
          Refresh
        </Button>
      </div>

      <div
        className='bottom-bubble'
        style={{
          position: 'absolute',
          width: '300px',
          zIndex: -1,
          height: '300px',
          borderRadius: '50%',
          background: 'linear-gradient(to right, #007DFE , #D6E6FF)',
          bottom: '-15%',
          right: '-5%',
        }}
      ></div>
      <div
        className='top-bubble'
        style={{
          position: 'absolute',
          zIndex: -1,
          width: '250px',
          height: '250px',
          borderRadius: '50%',
          background: 'linear-gradient(to right, #007DFE , #D6E6FF)',
          top: '20%',
          left: '-4%',
        }}
      ></div>
    </div>
  );
};

export default ErrorFound;
